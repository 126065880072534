<template>
  <div
    class="modal fade show"
    v-if="modalVisible"
  >
    <div class='modal__backdrop'></div>
    <div class="modal-dialog modal-xl zindex-2 modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title" id="exampleModalLabel">
            # {{ id }} · {{ $t('c_management.level_request_details') }}
          </h5>
          <button type="button" class="close" @click="closeModal()">
            <i aria-hidden="true" class="ki ki-close font-size-h4"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row d-flex flex-wrap px-2">
            <div class="col-md-6 col-sm-12">
              <div class="row">
                <p class="col-md-4 col-sm-12">{{ $t('commons.name') }} {{ $t('commons.surname') }}:</p>
                <b class="col-md-8 col-sm-12">{{ userFullName }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('c_management.level') }}:</p>
                <b class="col-md-8 col-sm-12">
                    <span
                        class="badge"
                        :class="rankClass"
                    >
                    {{ rankLabel }}
                    </span>
                </b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('c_management.identity_no') }}:</p>
                <b class="col-md-8 col-sm-12">{{ user.identity_number || '-' }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{$t('c_management.request_date') }}:</p>
                <b class="col-md-8 col-sm-12">{{ levelRequest.created_at || '-' }}</b>
              </div>
              <div class="row mt-2">
                <p class="col-md-4 col-sm-12">{{ $t('commons.status') }}:</p>
                <b class="col-md-8 col-sm-12">
                    <span class="badge" :class="statusClass">
                        {{ statusLabel }}
                    </span>
                </b>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <template v-if="isIndividual">
                <div class="row">
                  <p class="col-md-4 col-sm-12">{{ $t('commons.country') }}:</p>
                  <b class="col-md-8 col-sm-12">{{ user.country || '-' }}</b>
                </div>
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">{{ $t('commons.city') }}:</p>
                  <b class="col-md-8 col-sm-12 text-break">{{ user.city || '-' }}</b>
                </div>
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">{{ $t('commons.town') }}:</p>
                  <b class="col-md-8 col-sm-12">{{ user.town || '-' }}</b>
                </div>
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">{{ $t('user_details.address') }}:</p>
                  <b class="col-md-8 col-sm-12">{{ user.address || '-' }}</b>
                </div>
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">{{ $t('user_details.birthday') }}:</p>
                  <b class="col-md-8 col-sm-12">{{ user.birthday || '-' }}</b>
                </div>
                <div class="row mt-2">
                  <p class="col-md-4 col-sm-12">{{ $t('commons.nation') }}:</p>
                  <b class="col-md-8 col-sm-12">{{ user.nationality || '-' }}</b>
                </div>
              </template>
              <template v-if="isMpayCorporate">
                <div class="row mb-2">
                  <p class="col-md-4 col-sm-12">{{ $t('user_details.address') }}:</p>
                  <b class="col-md-8 col-sm-12">{{ user.address || '-' }}</b>
                </div>
                <div class="row mb-2">
                  <p class="col-md-4 col-sm-12">Tax Identification Number:</p>
                  <b class="col-md-8 col-sm-12">{{ user.tax_number || '-' }}</b>
                </div>
                <div class="row">
                  <p class="col-md-4 col-sm-12">Chamber of Commerce number of all involved entities:</p>
                  <div style="max-height: 78px; overflow-y: auto;" class="col-md-8 col-sm-12 d-flex flex-column" v-if="user.identity_information.length > 0">
                    <b v-for="(chamberOfCommerce, chamberOfCommerceKey) in user.identity_information" :key="`chamberOfCommerce-${chamberOfCommerceKey}`">{{ chamberOfCommerce }}</b>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="row d-flex flex-wrap px-2" v-if="attachments.length > 0">
            <table class="table table-bordered collapsed table-head-bg" style="width:100%">
                <thead>
                    <tr>
                    <th scope="col">{{ $t('commons.type') }}</th>
                    <th scope="col">{{ $t('commons.file') }}</th>
                    <th scope="col">{{ $t('commons.status') }}</th>
                    <th scope="col">{{ $t('commons.date') }}</th>
                    <th scope="col">{{ $t('commons.operations') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(fileItem, index) in attachments" :key="'attachment-' + index">
                        <td scope="row" class="align-middle">{{ $t(`c_management.${fileItem.type}`) || '-' }}</td>
                        <td scope="row" class="align-middle">
                            <a :href="fileItem.url" target="_blank">{{ $t('commons.show_file') }}</a>
                        </td>
                        <td scope="row" class="align-middle">{{ $t(`c_management.level_request_attachments_status.${fileItem.status}`) }}</td>
                        <td scope="row" class="align-middle">{{ fileItem.created_at }}</td>
                        <td scope="row" class="align-middle">
                            <div class="d-flex justify-content-start flex-shrink-0" v-if="+fileItem.status === 10">
                                <button type="button" class="btn btn-success btn-sm mr-1" @click="approveOrCancelFile(fileItem.id, '1')" :title="$t('commons.approve')">
                                    {{ $t('commons.approve') }}
                                </button>
                                <button type="button" class="btn btn-danger btn-sm" @click="approveOrCancelFile(fileItem.id, '2')" :title="$t('commons.cancel')">
                                    {{ $t('commons.cancel') }}
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer" v-if="isPendingOpertaionApproval">
          <button type="button" class="btn btn-success font-weight-bold" v-if="checkPermission('crm.level-request.confirm')" @click.prevent="approveDeclineProcess('1')">
            {{ $t('merchant.btn_approve') }}
          </button>
          <button type="button" class="btn btn-danger font-weight-bold" @click.prevent="approveDeclineProcess('2')">
            {{ $t('merchant.btn_decline') }}
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary"
            @click="closeModal()"
            data-dismiss="modal"
          >
            {{ $t('merchant.btn_close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {},
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapState({
      modalVisible: (state) => state.customerManagement.level_update_details_modal_visible,
      modalData: (state) => state.customerManagement.level_update_details_modal_data,
      permissions: (state) => state.customer.permissions,
    }),
    levelRequest() {
        return this.modalData?.levelRequest ?? {};
    },
    user() {
        return this.modalData?.user ?? {};
    },
    attachments() {
        return this.modalData?.attachments ?? []
    },
    levelService() {
        return this.modalData?.levelService ?? {}
    },
    id: function() {
        return this.levelRequest?.id ?? '';
    },
    isPendingOpertaionApproval: function() {
      return (this.levelRequest?.status === 10) || false;
    },
    userFullName() {
        const { name, surname } = this.user;
        if(name && surname) return `${name} ${surname}`
        return '-';
    },
    rankLabel() {
        return this.user?.level ? this.$t(`user_details.level_types.${this.user.level}`) : '-'
    },
    rankClass() {
        switch (this.user?.level) {
            case 10:
                return 'badge-light';
            case 20:
                return 'badge-primary';
            case 30:
                return 'badge-success';
            case 40:
                return 'badge-dark';
            default:
                return '';
        }
    },
    statusLabel() {
        return this.levelRequest?.status ? this.$t(`c_management.level_request_status.${this.levelRequest.status}`) : '-';
    },
    statusClass() {
        switch (this.levelRequest?.status) {
            case 10:
                return 'badge-warning';
            case 20:
                return 'badge-success';
            case 30:
                return 'badge-danger';
            default:
                return 'badge-light'
        }
    },
    isIndividual() {
      return `${this.user.type}` === '10';
    },
    isMpayCorporate() {
      const parent_id = this.user?.parent_id || null;
      const checkType = this.user?.type ? `${this.user.type}` === '70' : false;
      return checkType && !parent_id;
    }
  },
  methods: {
    closeModal() {
      this.$store.commit('customerManagement/TOGGLE_LEVEL_UP_DETAILS_MODAL', false);
    },
    async approveDeclineProcess(action) {
      // approve: 1, decline: 2

      // 1 send sms code request
      // 2 open sms modal
      // 3 confirm sms
      // 4 close sms modal and get request again

      await this.$store.dispatch('customerManagement/LEVEL_UP_SEND_SMS', { id: this.id, action })
    },
    approveOrCancelFile(fileId, action) {
      this.$store.dispatch('customerManagement/ATTACHMENT_CONFIRMATION', { fileId, action, levelRequestId: this.id })
    },
    checkPermission( permission) {
      let status = false;
      this.permissions?.forEach(function (item) {
        if (item.name == permission) {
          status = true;
        }
      });
      return status
    },
  },
  created() { },
};
</script>
<style scoped>
  .show {
    display: block;
  }
  .modal__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
</style>